import React from "react";

const Features = ({ features }) => {
  console.log(features);
  return (
    <>
      <h4 className="mt-2">Car Features</h4>
      <div className="row">
        {features.map((option, index) => (
          <div className="col-6 my-1" key={index}>
            {option.name}
          </div>
        ))}
      </div>
    </>
  );
};
export default Features;
