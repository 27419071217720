import * as React from "react";
import * as style from "../scss/carDetails.module.scss";
import AdditionalInfo from "../components/AdditionalInfo";
import CarDetail from "../components/CarDetail";
import Contact from "../components/Contact";
import Features from "../components/Features";
import Layout from "../components/Layout";
import { getImage, StaticImage, GatsbyImage } from "gatsby-plugin-image";
const CarDetails = ({ pageContext, location }) => {
  const { node, navigations, localizations } = pageContext;
  const defaultImage = "../images/default-placeholder.png";
  return (
    <Layout
      navigations={navigations}
      location={location}
      localizations={localizations}
    >
      <div className="row">
        <div className="card col-lg-10  col-12 ">
          <div className="text-center">
            {(!node.images.length && (
              <>
                {
                  <StaticImage
                    src={defaultImage}
                    alt="alt"
                    width="auto"
                    height="auto"
                    className={style.directoryCarImg}
                  />
                }
              </>
            )) || (
              <>
                {
                  <GatsbyImage
                    image={getImage(node.images[0].localFile)}
                    alt="alt"
                    width="auto"
                    height="auto"
                    className={style.directoryCarImg}
                  />
                }
              </>
            )}
          </div>
          <div className="card-body">
            <h1 className="card-title">{node.title}</h1>
            <h4>HKD ${node.price}</h4>
            <hr />
            <CarDetail title="Model" value={node.model?.name} />
            <CarDetail title="Year" value={node.year} />
            <CarDetail title="Mileage" value={node.mileage} />
            <CarDetail title="Transmission" value={node.transmission?.name} />
            <CarDetail title="Engine size" value={node.engineSize} />

            <hr />
            <Features features={node.options} />
            <AdditionalInfo content={node.information} />
          </div>
        </div>

        {node.contact && (
          <>
            <div className="col-lg-2 card">
              <div className="card-body">
                <Contact contact={node.contact} />
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};
export default CarDetails;
