import React from "react";

const CarDetail = ({ title, value }) => {
  return (
    <div className="row">
      <div className="col">{title}</div>
      <div className="col">{value ?? "N/A"}</div>
    </div>
  );
};
export default CarDetail;
