import React from "react";

const Contact = ({ contact }) => {
  return (
    <>
      <h3>Contact</h3>
      <div>
        <p>
          Seller
          <br />
          {contact.firstname} {contact.lastname}
        </p>

        <p>
          Email
          <br />
          <a href={`mailto:${contact.email}`}>{contact.email}</a>
        </p>

        <p>
          Whatsapp
          <br />
          <a
            rel="noreferrer"
            target="_blank"
            href={`https://wa.me/${contact.whatsapp}`}
          >
            {contact.whatsapp}
          </a>
        </p>

        <p>
          Wechat
          <br />
          <a
            rel="noreferrer"
            target="_blank"
            href={`weixin://dl/chat?${contact.wechat}`}
          >
            {contact.wechat}
          </a>
        </p>
      </div>
    </>
  );
};
export default Contact;
