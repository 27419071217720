import React from "react";

const AdditionalInfo = ({ content }) => {
  return (
    <div>
      <h4 className="mt-2">Additional information</h4>
      <p className="card-text">{content}</p>
    </div>
  );
};
export default AdditionalInfo;
